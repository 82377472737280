// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/zeit/2df0bfc/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/zeit/2df0bfc/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/zeit/2df0bfc/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-insta-clone-js": () => import("/zeit/2df0bfc/src/pages/projects/insta-clone.js" /* webpackChunkName: "component---src-pages-projects-insta-clone-js" */),
  "component---src-pages-projects-key-conservation-js": () => import("/zeit/2df0bfc/src/pages/projects/key-conservation.js" /* webpackChunkName: "component---src-pages-projects-key-conservation-js" */),
  "component---src-pages-projects-party-planner-js": () => import("/zeit/2df0bfc/src/pages/projects/party-planner.js" /* webpackChunkName: "component---src-pages-projects-party-planner-js" */),
  "component---src-pages-projects-pintereach-js": () => import("/zeit/2df0bfc/src/pages/projects/pintereach.js" /* webpackChunkName: "component---src-pages-projects-pintereach-js" */),
  "component---src-pages-projects-project-1-js": () => import("/zeit/2df0bfc/src/pages/projects/project1.js" /* webpackChunkName: "component---src-pages-projects-project-1-js" */),
  "component---src-pages-projects-simpsons-say-js": () => import("/zeit/2df0bfc/src/pages/projects/simpsons-say.js" /* webpackChunkName: "component---src-pages-projects-simpsons-say-js" */)
}

